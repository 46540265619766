import "../Styles/Global Components/Teamcard.scss";


const TeamCard = (person) => {
  return (
    <div>

      <a href={person.link} target="_blank" rel="noreferrer">
       <img className="team-image"  src={person.photo} alt={person.name} />
       </a>
       
      
      <div className="team-name"> {person.name} </div>
      <div className="team-position"> {person.position} </div>
    </div>
  );
};

export default TeamCard;
