import React from "react";
import { Link, useParams } from "react-router-dom";
import data from "../Text/Pages/History.jsx";
import TeamCard from "../Global Components/Teamcard";
import Accordion from "../Global Components/Accordion";

import "../Styles/Pages/CarInfo.scss";

function CarInfo() {
  const miniCar = (miniCar, shown) => {
    return (
      <div className="miniCar" style={{ opacity: !shown && 0 }}>
        <Link to={shown && "/history/" + miniCar.route} className="link">
          <TeamCard name={miniCar.name} position={""} photo={miniCar.image} />
        </Link>
      </div>
    );
  };

  let { carName } = useParams();
  let car = data.section1.pastCars.filter((item) => item.route === carName)[0];
  let prevCar = data.section1.pastCars.filter(
    (item) => item.route === car.prev
  )[0];
  let nextCar = data.section1.pastCars.filter(
    (item) => item.route === car.next
  )[0];

  return (
    <div>
      <div className="carDisplay">
        {prevCar ? miniCar(prevCar, true) : miniCar(car, false)}
        <TeamCard name={car.name} position={car.summary} photo={car.image} />
        {nextCar ? miniCar(nextCar, true) : miniCar(car, false)}
      </div>
      {car.details && (
        <div>
          <div className="subheading">Overview</div>
          <div className="divider"></div>
          <div className="carDetails">
            <Accordion data={car.details}></Accordion>
          </div>
        </div>
      )}
    </div>
  );
}

export default CarInfo;
