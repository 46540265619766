import mfe19image from "../../Assets/gallery/history/mfe19.jpeg";
import mfe20image from "../../Assets/gallery/history/mfe20.jpeg";
import mfe21image from "../../Assets/gallery/history/mfe21.jpeg";
import mfe22image from "../../Assets/gallery/history/mfe22.jpeg";

const data = {
  pageTitle: "History",
  section1: {
    title: "Past Cars",
    pastCars: [
      {
        name: "MFE 23",
        route: "mfe23",
        prev: "mfe22",
        summary:
          "MFE23 is the first car to roll out of our shop in 2 years due to the Covid-19 pandemic. It is a new design from the ground up and is engineered to be our best yet.",
        image: mfe22image,
      },
      {
        name: "MFE 22",
        route: "mfe22",
        prev: "mfe21",
        next: "mfe23",
        summary:
          "MFE22 is the first car to roll out of our shop in 2 years due to the Covid-19 pandemic. It is a new design from the ground up and is engineered to be our best yet.",
        image: mfe22image,
      },
      {
        name: "MFE 21",
        route: "mfe21",
        prev: "mfe20",
        next: "mfe22",
        summary:
          "MFE21 is the third car since the merge of McGill Formula Electric and McGill Racing Team in 2017. It is our first prototype designed with a drivetrain consisting of 4 in-hub motors.",
        image: mfe21image,
      },
      {
        name: "MFE 20",
        route: "mfe20",
        prev: "mfe19",
        next: "mfe21",
        summary:
          "MFE20 was built with a focus on improving our previous prototype through increasing reliability, and weight savings. It placed 1st overall at FSAE Electric 2019, as well as finishing 1st in design and setting a new North American acceleration record of 3.687s at the same competition.",
        image: mfe20image,
        details: [
          {
            Title: "Performance",
            array: [
              "0-100km/h: 2.4 seconds",
              "Top speed: 125 km/h",
              "Weight: 174kg (384lbs)",
            ],
          },
          {
            Title: "Chassis",
            array: [
              "Full CFRP Monocoque",
              "Torsional Stiffness of 1399 Nm/deg",
            ],
          },
          {
            Title: "Suspension",
            array: [
              "18 x 6.0 – 10 LCO Hoosier",
              "Push rod actuated spring and damper with adjustable U-bar anti roll bar.",
            ],
          },
          {
            Title: "Drivetrain",
            array: [
              "3.46 fixed gear ratio",
              "Drexler Limited Slip Differential",
              "Aermet 100 SuperAlloy Halfshafts",
            ],
          },
          {
            Title: "Powertrain",
            array: [
              "300V, 6.39kWh lithium polymer accumulator",
              "Carbon fiber accumulator container",
              "9.6kW regenerative braking",
              "Rinehart PM100DX motor controller",
              "Emrax 228MV Motor",
            ],
          },
          {
            Title: "Brakes",
            array: [
              "Independent front/rear brake lines with bias bar adjustment",
              "APRacing calipers (4/2 piston F/R)",
              "AISI 1020 Custom Floating discs",
            ],
          },
          {
            Title: "Aerodynamics",
            array: [
              "CLA of 4.22",
              "CLD of 1.58",
              "Multi element Front wing and mid region, 4 element rear wing",
              "Complete underbody including splitter and diffuser",
              "Carbon fiber skin/foam core wing construction",
            ],
          },
          {
            Title: "Electronics",
            array: [
              "Custom designed CAN Nodes used reduce noise in logged data",
              "In house BMS (Battery Management System)",
            ],
          },
        ],
      },
      {
        name: "MFE 19",
        route: "mfe19",
        next: "mfe20",
        summary:
          "MFE19 was the first car  built after the merge of our two teams. It combines the aerodynamics and chassis design expertise of our combustion team with the electric drivetrain developed by our electric team.",
        image: mfe19image,
        details: [
          {
            Title: "Performance",
            array: [
              "0-100km/h: 2.6 seconds",
              "Top speed: 125 km/h",
              "Weight: 185kg (409lbs)",
            ],
          },
          {
            Title: "Chassis",
            array: [
              "Full CFRP Monocoque",
              "Torsional Stiffness of 1356 Nm/deg",
            ],
          },
          {
            Title: "Suspension",
            array: [
              "18 x 6.0 – 10 LCO Hoosier",
              "Push rod actuated spring and damper with adjustable U-bar anti roll bar.",
            ],
          },
          {
            Title: "Drivetrain",
            array: [
              "3.46 fixed gear ratio",
              "Drexler Limited Slip Differential",
              "Aermet 100 SuperAlloy Halfshafts",
            ],
          },
          {
            Title: "Powertrain",
            array: [
              "300V, 6.39kWh lithium polymer accumulator",
              "Carbon fiber accumulator container",
              "9.6kW regenerative braking",
              "Rinehart PM100DX motor controller",
              "Emrax 228MV Motor",
            ],
          },
          {
            Title: "Brakes",
            array: [
              "Independent front/rear brake lines with bias bar adjustment",
              "APRacing calipers (4/2 piston F/R)",
              "AISI 1020 Custom Floating discs",
            ],
          },
          {
            Title: "Aerodynamics",
            array: [
              "CLA of 4.07",
              "CLD of 1.47",
              "Multi element Front wing and mid region, 4 element rear wing",
              "Complete underbody including splitter and diffuser",
              "Carbon fiber skin/foam core wing construction",
            ],
          },
          {
            Title: "Electronics",
            array: [
              "Custom designed Master/Slave vehicle controller using CAN communication",
              "In house BMS (Battery Management System)",
            ],
          },
        ],
      },
    ],
  },
};

export default data;
