import { useState } from "react";
import "../Styles/Global Components/Accordion.scss";

function Accordion(props) {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }

    setSelected(i);
  };

  return (
    <div className="accordion header3">
      {props.data.map((item, i) => (
        <div className="item">
          <div className="title" onClick={() => toggle(i)}>
            <h2>{item.Title}</h2>
            <span>{selected === i ? "-" : "+"}</span>
          </div>

          {item.subtext && (
            <div className={selected === i ? "content show" : "content"}>
              {item.subtext}
            </div>
          )}
          {item.array &&
            item.array.map((item2) => (
              <div className={selected === i ? "content show" : "content"}>
                {item2}
              </div>
            ))}
        </div>
      ))}
    </div>
  );
}

export default Accordion;
