import React, { useRef, useState, useEffect } from "react";
import { PieChart, Pie, Legend, Tooltip } from "recharts";
import "../Styles/Global Components/Piechart.scss";

function Piechart({ isMobile }) {
  const data = [
    { name: "Mechanical Engineering", value: 45, fill: "#F2C94C" },
    { name: "Electrical Engineering", value: 20, fill: "#5D816A" },
    { name: "Other Engineering", value: 20, fill: "black" },
    { name: "Computer Engineering", value: 10, fill: "#89D2D9" },
    { name: "Business/Finance", value: 5, fill: "#3C4058" },
  ];
  const renderColorfulLegendText = (value, entry) => {
    return <span style={{ color: "white", fontWeight: 500 }}>{value}</span>;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="piecharthover">
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <PieChart width={isMobile ? 300 : 500} height={isMobile ? 300 : 500}>
      <Legend
        height={0}
        iconType="square"
        layout="horizontal"
        iconSize={15}
        padding={5}
        formatter={renderColorfulLegendText}
      />
      <Pie
        stroke="none"
        data={data}
        outerRadius={isMobile ? 75 : 150}
        innerRadius={isMobile ? 30 : 75}
      ></Pie>
      <Tooltip content={<CustomTooltip />} />
    </PieChart>
  );
}

export default Piechart;
