import React from "react";
import "../Styles/Global Components/Footer.scss";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <section class="right-column">
        <h3 class="title-footer">Mcgill Formula Electric</h3>

        <section class="details-location">
          <p>McGill Formula Electric</p>
          <p>817 Sherbrooke Street West</p>
          <p>MacDonald Engineering Building,</p>
          <p>Room 270</p>
          <p>Montreal, QC H3A 0C3</p>
        </section>
      </section>
      <section /> {/*This section is used as a blank space to spearate both sides */} 
      <nav class="left-column">
        <section class="links">
          <br />
          <Link to="/team">Team</Link>
          <br />
          <Link to="/history">History</Link>
          <br />
          <Link to="/about">About</Link>
          <br />
          <Link to="/sponsors">Sponsors</Link>
          <br />
          <a href="/gallery">Gallery</a>
          <br />
        </section>

        <div class="socials">
          <a
            href="https://www.facebook.com/MCGILLFSAE/"
            class="fa fa-facebook"
            id="facebook"
          ></a>
          <a
            href="https://www.instagram.com/mcgillformulaelectric/"
            class="fa fa-instagram"
            id="instagram"
          ></a>
          <a
            href="https://twitter.com/mcgillfsae"
            class="fa fa-twitter"
            id="twitter"
          ></a>
        </div>
      </nav>
    </footer>
  );
}

export default Footer;
