import React, { useState, useEffect } from "react";
import "../Styles/Pages/Team.scss";

import TeamCard from "../Global Components/Teamcard.jsx";
import { TeamMember } from "../Text/Pages/TeamMembers.jsx";
import Spinner from "../Global Components/Spinner";

export default function Team() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
      <div className={isLoading ? "hidden fade" : " fade"}>
        <main className="team-content">
          <div className="page-title"> Meet the 2022 - 2023 MFE Team! </div>
          <div class="divider"></div>
          <div className="subheading">MANAGEMENT</div>
          <div className="team-members3-grid">
            {TeamMember[0].management.map((person) => TeamCard(person))}
          </div>

          <div class="divider"></div>
          <div className="subheading">BUSINESS</div>
          <div className="team-members3-grid team-members2-grid ">
            {TeamMember[0].business.map((person) => TeamCard(person))}
          </div>

          <div class="divider"></div>
          <div className="subheading">Frame & Body</div>
          <div className="team-members3-grid">
            {TeamMember[0].framebody.map((person) => TeamCard(person))}
          </div>

          <div class="divider"></div>
          <div className="subheading">DYNAMICS</div>
          <div className="team-members3-grid">
            {TeamMember[0].dynamics.map((person) => TeamCard(person))}
          </div>

          <div class="divider"></div>
          <div className="subheading">ELECTRONICS</div>
          <div className="team-members3-grid">
            {TeamMember[0].electronics.map((person) => TeamCard(person))}
          </div>

          <div class="divider"></div>
          <div className="subheading">POWERTRAIN</div>
          <div className="team-members3-grid">
            {TeamMember[0].powertrain.map((person) => TeamCard(person))}
          </div>

          <div class="divider"></div>
          <div className="subheading">VEHICLE DYNAMICS AND CONTROLS</div>
          <div className="team-members3-grid">
            {TeamMember[0].vehicledynamicscontrol.map((person) =>
              TeamCard(person)
            )}
          </div>
        </main>
      </div>
    </>
  );
}
