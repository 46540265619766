import '../Styles/Global Components/Socials.scss';
import instagram from'../Assets/socials/instagram.svg';
import facebook from'../Assets/socials/facebook.svg';
import linkedin from'../Assets/socials/linkedin.svg';

//<p className='text12'> what is going on</p>
//<img src = {facebook} className= 'facebook' />
function Socials() {

    return <div className='flexcontainer'>
        
        <a href="https://www.linkedin.com/company/mcgill-formula-electric-mfe/"> <img src = {linkedin} className= 'linkedin' />
       </a> 
        
        <a href="https://www.facebook.com/MCGILLFSAE/"><img src = {facebook} className= 'facebook' />
        </a>
        <a href="https://www.instagram.com/mcgillformulaelectric/"><img src = {instagram} className='instagram' />
        </a>
       
    
    </div>;
    


}

export default Socials;