import React, { useEffect, useState, useRef } from "react";
import "./Styles/Pages/Home.scss";
import Home from "./Pages/Home.jsx";
import Team from "./Pages/Team.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Pages/About.jsx";
import Gallery from "./Pages/Gallery.jsx";
import Sponsors from "./Pages/Sponsors.jsx";
import History from "./Pages/History.jsx";
import Footer from "./Global Components/Footer.jsx";
import CarInfo from "./Pages/CarInfo.jsx";

import Navbar from "./Global Components/Navbar.jsx";

function App() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      windowSize.current = [window.innerWidth, window.innerHeight];
      if (windowSize.current[0] < 501) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <Router>
        <div className="app-container">
          <Navbar />
          <div className="app-wrapper">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/team" element={<Team />} />
              <Route path="/about" element={<About isMobile={isMobile} />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/sponsors" element={<Sponsors />} />
              <Route path="/history" element={<History />} />
              <Route path="/history/:carName" element={<CarInfo />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
