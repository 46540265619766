import React from "react";
import Piechart from "../Global Components/Piechart.jsx";

import Text from "../Text/Pages/About.json";
import teamPic from "../Assets/about/mfe23team.jpeg";

import "../Styles/Pages/About.scss";

function About({ isMobile }) {
  return (
    <div>
      <div className="page-title">{Text.pageTitle}</div>
      <div class="divider"></div>

      <div className="stats">
        <div className="whoWeAre">
          <div className="subheading">{Text.section1.title}</div>
          <div className="body">{Text.section1.body}</div>
        </div>
        <div className="piechart-container">
          <Piechart isMobile={isMobile}></Piechart>
        </div>
      </div>
      <div className="subheading">{Text.section2.title}</div>
      <div class="divider"></div>
      <img src={teamPic} className="teamPic" />
      <div className="subheading">{Text.section3.title}</div>
      <div class="divider"></div>
      <div className="card-container">
        {Text.section3.cards.map((card) => {
          return (
            <div className="card">
              <div className="header3">{card.title}</div>
              <div className="body">{card.body}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default About;
