/*
 * The loading spinner component displays a looping racecar animation until the content is loaded
 */
import React from "react";
import '../Styles/Global Components/Spinner.scss';

export default function Spinner({ colour = "#FFFFFF", speed = 1 }) {

  // include ALL the SVG as components to allow for deep animation and customization in CSS
  return (
    <svg className="spinner-svg" viewBox="0 0 213.328 90.199997" version="1.1" style={{ stroke: colour }}>

      {/* Speed Swivel Things */}
      <g className="spinner-svg--wooshes">
        <path className="spinner-svg--woosh" d="m 10.233231,15.199811 c 0,0 5.619364,-5.5218899 11.699289,-5.5218899 6.079924,0 7.448871,7.8554189 14.542116,7.8554189 7.093246,0 11.504791,-5.626822 11.504791,-5.626822" />
        <path className="spinner-svg--woosh" d="m 2,35.033764 c 0,0 5.619364,-5.52189 11.699289,-5.52189 6.079924,0 7.448871,7.855419 14.542116,7.855419 7.093246,0 11.504791,-5.626822 11.504791,-5.626822" />
        <path className="spinner-svg--woosh" d="m 10.359897,54.286858 c 0,0 5.619364,-5.52189 11.699289,-5.52189 6.079924,0 7.448871,7.855419 14.542116,7.855419 7.093246,0 11.504791,-5.626822 11.504791,-5.626822"  />
      </g>

      {/* The Car */}
      <g className="spinner-svg--bounce_animation">
        <g className="spinner-svg--car" transform="matrix(1.0000041,0,0,1.0000041,40.582383,-114.4432)">

          <g className="spinner-svg--tires">
            {/* Back Tire */}
            <circle className="spinner-svg--backtire" cx="54.355247" cy="188.62024" r="14.110709" />
            {/* Front Tire */}
            <circle className="spinner-svg--fronttire" cx="127.2475" cy="188.62036" r="14.110709" />
          </g>
          
          {/* Back */}
          <path className="spinner-svg--back" d="m 19.759754,166.18459 c -10.5568693,-12.30521 -5.931244,-44.77388 0,-48.63939 8.014083,-5.22294 45.3461,10.63987 45.3461,20.01308 0,9.37322 -3.624518,14.34775 -9.626548,16.21313 -4.110321,1.27745 -13.746314,1.41011 -19.253093,14.94648 -1.995522,4.90525 -12.850471,1.68154 -16.466459,-2.5333 z" />
          
          {/* Body of the car */}
          <g className="spinner-svg--body">
            {/* Bottom Body */}
            <path className="spinner-svg--bottom" d="m 74.98573,192.53093 h 31.91961" />
            {/* Main Body */}
            <path className="spinner-svg--main" d="m 146.67816,192.78426 c 0,0 24.06637,1.11605 24.06637,-5.0666 0,-10.52721 -16.98601,-2.77906 -17.73311,-13.67983 0,-6.58658 -16.98953,-19.00773 -27.35966,-21.7864 -14.38477,-3.51947 -24.10826,2.31755 -32.172927,4.55995 -9.295475,0.51554 -48.748181,1.42491 -48.892722,14.18648"/>
          </g>

        </g>
      </g>
    </svg>

  )

}