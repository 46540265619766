import React from "react";
import { Link } from "react-router-dom";
import TeamCard from "../Global Components/Teamcard.jsx";

import data from "../Text/Pages/History.jsx";
import "../Styles/Pages/History.scss";

function History() {
  return (
    <div>
      <div className="page-title">Our History</div>
      <div className="subheading">Past Cars</div>
      <div className="divider"></div>
      <div className="pastcars team-members2-grid team-content">
        {data.section1.pastCars.map((car) => (
          <Link
            to={car.route}
            style={{
              color: "#FFFFFF",
              textDecoration: "none",
              margin: "20px 10px",
            }}
          >
            <TeamCard
              name={car.name}
              position={car.summary}
              photo={car.image}
            />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default History;
