import React, { useState } from "react";
import "../Styles/Global Components/TabSwitcher.scss";

//object that has both the header and the information for the three seperate tabs
const types = [
  {
    title: "Engineering",
    info: "At MFE, students apply the strong fundamental knowledge that is taught at McGill by developing proven design processes, numerical tools and validation methodologies. This engineering design prowess is highlighted by our strong performance in international competitions.",
  },
  {
    title: "Innovation",
    info: "We are continuously pushing the technical boundaries by utilizing state of the art tools in computational fluid dynamics, finite element analysis and vehicle dynamics simulations to develop our prototypes. This enables us to build one of the most sophisticated Formula Student vehicles in North America",
  },
  {
    title: "Success",
    info: "Our team consistently finishes in the top 4 at international competitions; we aim to continue this tradition of success. However, trophies are not the only measure of success; we hold ourselves to a very high standard and take pride and enjoyment in the quality, efficiency and performance of our electric vehicles",
  },
];

function TabSwitcher() {
  // react hook with the current state and tracking function
  const [active, setActive] = useState(types[0]);

  return (
    <div className="tabswitcher">
      <div className="tabs">
        {types.map((type) => (
          // creates a class name based if it the current state is active or not
          //get styled differently based on that fact
          <div
            className={type === active ? "active" : "tab"}
            key={type}
            active={active === type}
            onClick={() => setActive(type)}
          >
            {type.title}
          </div>
        ))}
      </div>

      <div className="info">
        {active.info /* displays the seperate info pargraphs */}
      </div>
    </div>
  );
}

export default TabSwitcher;
