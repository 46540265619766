import React, { useState, useRef, useEffect } from "react";

// import the styles
import '../Styles/Global Components/Carousel.scss';

import IconNext from '../Assets/icon-next.svg';
import IconBack from '../Assets/icon-back.svg';

// keep track of the current image in a way that is universal
let realCurrentImage = 0;

// take in an array of srcs as a prop
export default function Carousel ({ images, height = 340 }) {

  // keep track of which image is currently centered
  const [ currentImage, setCurrentImage ] = useState(0);

  // reference to the scroll container so that its scroll position can be updated
  const containerRef = useRef(null);

  // when the component is updated, scroll it to the correct image
  useEffect(() => {

    // scroll the carousel to the current position
    containerRef.current.scrollTo({
      left: currentImage == 0 ? 0 : getScrollPosition(containerRef.current, currentImage),
      behavior: 'smooth'
    });
  });

  // auto scroll the carousel every 5 seconds
  useEffect(() => {
    const timeout = setInterval(() => {
      realCurrentImage++;
      setCurrentImage(realCurrentImage % images.length);

    }, 5000);

    return () => {
      clearInterval(timeout);
    }
  });

  // this event is run when the carousel is scrolled
  const onCarouselScroll = event => {
    // loop until the closest image is found
    for (realCurrentImage = 0; realCurrentImage < images.length; realCurrentImage++) {
      if (containerRef.current.children[realCurrentImage].getBoundingClientRect().left - containerRef.current.getBoundingClientRect().left >= containerRef.current.getBoundingClientRect().right - containerRef.current.children[realCurrentImage].getBoundingClientRect().right) break;
    }
    console.log(containerRef.current.children[realCurrentImage].getBoundingClientRect().left - containerRef.current.getBoundingClientRect().left, containerRef.current.getBoundingClientRect().right - containerRef.current.children[realCurrentImage].getBoundingClientRect().right)
    console.log(realCurrentImage);
  };

  return (
    <div className="carousel">
      <div className="carousel-container" style={{height: `${height}px`}} ref={containerRef} onScroll={onCarouselScroll}>
        {/* loop over all the inputted images and create an element for them */}
        {images.map(src => <img key={src} src={src} className="carousel-img"></img>)}
      </div>
      <button className="carousel-button carousel-button--next" onClick={() => setCurrentImage((realCurrentImage + 1) % images.length)}><img src={IconNext} alt=">"></img></button>
      <button className="carousel-button carousel-button--previous" onClick={() => {
        // if the current image is 0, then move to the last one
        if (realCurrentImage == 0) setCurrentImage(images.length - 1);
        else setCurrentImage(realCurrentImage - 1);
      }}><img src={IconBack} alt="<"></img></button>
    </div>
  );


}

// takes in the container of the scroller, gets its contents and calculates how much it needs to be scrolled
function getScrollPosition (container, index) {

  let scrollTotal = 0;

  // get the width of every child before the current one in the container
  for (let i = 0; i < index; i++) {

    // add the width from the ith image
    scrollTotal += container.children[i].getBoundingClientRect().width;

  }

  scrollTotal -= (container.getBoundingClientRect().width - container.children[index].getBoundingClientRect().width) / 2;

  return scrollTotal;

}