import React, { useEffect, useRef } from "react";

import Footer from "../Global Components/Footer.jsx";

// the text is in a JSON file to allow easier editing for people inexperienced with web dev
import Text from "../Text/Pages/Home.json";
import TabSwitcher from "../Global Components/TabSwitcher.jsx";
import "../Styles/Pages/Home.scss";
import Socials from "../Global Components/Socials.jsx";
import LandingCar from "../Assets/landing-car2.png";

function Home() {
  // image element needs a reference so a style can be set on load
  const backgroundRef = useRef(null);

  return (
    <>
      <Socials />
      <div>
        <div className="firstSection">
          <img src={LandingCar} className="landing-car"></img>
          <div className="text">
            <div className="header1">McGill Formula Electric</div>
            <div className="subheading">Formula SAE Champions 2019-2021</div>
          </div>
        </div>
        <div className="secondSection"></div>
        <div className="thirdSection">
          <div className="tabSwitcher">
            <TabSwitcher />
          </div>
        </div>
        <div className="fourthSection" />
      </div>
    </>
  );
}

export default Home;
