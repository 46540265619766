import React, { useState } from "react";

import "../Styles/Global Components/Navbar.scss";

import MFELogo from "../Assets/logo.png";
import Menu from "../Assets/navbar-menu.svg";
import CloseNavbar from "../Assets/navbar-close.svg";
import { Link } from "react-router-dom";

function Navbar() {
  const [linksOpen, setLinksOpen] = useState(false);

  return (
    <div className="navbar-mobile navbar-desktop">
      <a href="">
        <img
          src={MFELogo}
          className="logo-mobile logo-desktop"
          alt="McGill Formula Electric"
        />
      </a>

      <div className="hamburger hamburger-desktop">
        <button
          className="hamburger-button"
          onClick={() => setLinksOpen(!linksOpen)}
        ></button>
        <img className="hamburger-icon" src={Menu} />
      </div>

      <div
        className={
          linksOpen
            ? "navbar-links-mobile open"
            : "navbar-links-mobile navbar-links-desktop"
        }
      >
        <div className="navbar-pages-mobile">
          <Link
            to="/"
            className="link link-desktop"
            onClick={() => {
              setLinksOpen(false);
            }}
          >
            Home
          </Link>
          <Link
            to="/team"
            className="link link-desktop"
            onClick={() => {
              setLinksOpen(false);
            }}
          >
            Team
          </Link>
          <Link
            to="/history"
            className="link link-desktop"
            onClick={() => {
              setLinksOpen(false);
            }}
          >
            History
          </Link>
          <Link
            to="/about"
            className="link link-desktop"
            onClick={() => {
              setLinksOpen(false);
            }}
          >
            About
          </Link>
          <Link
            to="/sponsors"
            className="link link-desktop"
            onClick={() => {
              setLinksOpen(false);
            }}
          >
            Sponsors
          </Link>
          <Link
            to="/gallery"
            className="link link-desktop"
            onClick={() => {
              setLinksOpen(false);
            }}
          >
            Gallery
          </Link>
          <a
            href=""
            className="link link-desktop link-contact-us"
            onClick={() => {
              setLinksOpen(false);
            }}
          >
            Contact Us
          </a>
        </div>

        <div className="mobile-navbar-close navbar-close-desktop">
          <button
            className="close-button"
            onClick={() => setLinksOpen(!linksOpen)}
          ></button>
          <img className="navbar-close-icon" src={CloseNavbar} />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
