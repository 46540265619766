function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../../Assets/team", false, /.(png|jpe?g|svg)$/)
);

export const TeamMember = [
  {
    management: [
      {
        link: "https://www.linkedin.com/in/walker-s-45215412a/",
        name: "WALKER SINGLETON",
        position: "TEAM CAPTAIN",
        photo: images["WalkerSingleton.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/catherine-van-gheluwe-b977b2158",
        name: "CATHERINE VAN GHELUWE",
        position: "ASSISTANT CAPTAIN",
        photo: images["CatherineVanGheluwe.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/alex-fricker-88220b199/",
        name: "ALEXANDER FRICKER",
        position: "MECHANICAL DIRECTOR",
        photo: images["AlexanderFricker.jpg"],
      },
      {},
      {
        link: "https://www.linkedin.com/in/guillaume-richard-b4766517b/",
        name: "GUILLAUME RICHARD",
        position: "ELECTRICAL DIRECTOR",
        photo: images["GuillaumeRichard.jpg"],
      },
    ],
    business: [
      {
        link: "https://www.linkedin.com/in/jadakethompson/",
        name: "JADA KE THOMPSON",
        position: "FINANCE LEAD",
        photo: images["JadaKeThompson.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/sarah-dayazada-8735a7198/",
        name: "SARAH DAYAZADA",
        position: "MARKETING LEAD",
        photo: images["SarahDayazada.jpg"],
      },
    ],

    framebody: [
      {
        link: "https://www.linkedin.com/in/dave8/",
        name: "DAVID GREEN",
        position: "TECHNICAL ADVISOR",
        photo: images["DavidGreen.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/alexander-casalanguida/",
        name: "ALEXANDER CASALANGUIDA",
        position: "AERODYNAMICS AND COOLING LEAD",
        photo: images["AlexanderCasalanguida.jpg"],
      },
      {
        
        link: "https://www.linkedin.com/in/cyril-comaty/",
        name: "CYRIL COMATY",
        position: "CHASSIS CO-LEAD",
        photo: images["CyrilComaty.jpg"],
      },
      {},
      {
        link: "https://www.linkedin.com/in/louisequincy/",
        name: "LOUISE QUINCY",
        position: "CHASSIS CO-LEAD",
        photo: images["LouiseQuincy.jpg"],
      },
    ],

    dynamics: [
      {
        link: "https://www.linkedin.com/in/luca-agopian-b0113b217/",
        name: "LUCA AGOPIAN",
        position: "CARBON RIMS LEAD",
        photo: images["LucaAgopian.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/karim-sabbagh-12aa89208/",
        name: "KARIM AL SABBAGH",
        position: "COCKPIT CONTROLS CO-LEAD",
        photo: images["KarimAlSabbagh.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/hiraku165/",
        name: "HIRAKU MURAKAMI",
        position: "COCKPIT CONTROLS CO-LEAD",
        photo: images["HirakuMurakami.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/antoine-voyer/",
        name: "ANTOINE VOYER",
        position: "DRIVETRAIN LEAD",
        photo: images["AntoineVoyer.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/garrett-gerrard-4a7a6617a/",
        name: "GARRETT GERARD",
        position: "SUSPENSION CO-LEAD",
        photo: images["GarrettGerard.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/monty-tebbutt/",
        name: "MONTY TEBBUTT",
        position: "SUSPENSION CO-LEAD",
        photo: images["MontyTebbutt.jpg"],
      },
    ],

    electronics: [
      {
        link: "https://www.linkedin.com/in/aidan-gerkis-8a7197171/",
        name: "AIDAN GERKIS",
        position: "ELECTRICAL ADVISOR",
        photo: images["AidanGerkis.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/soumik-podder-04b36721a/",
        name: "SOUMIK PODDER",
        position: "HARNESS & DAQ LEAD",
        photo: images["SoumikPodder.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/youjung-kang-980782254/",
        name: "YOUJUNG KANG",
        position: "PCBs CO-LEAD",
        photo: images["YoujungKang.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/georgiy-danylenko/",
        name: "GEORGIY DANYLENKO",
        position: "PCBSs CO-LEAD",
        photo: images["GeorgiyDanylenko.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/catherine-van-gheluwe/",
        name: "CATHERINE VAN GHELUWE",
        position: "SOFTWARE CO-LEAD",
        photo: images["CatherineVanGheluwe.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/mario-bouzakhm/",
        name: "MARIO BOUZAKHM",
        position: "SOFTWARE CO-LEAD",
        photo: images["MarioBouzakhm.jpg"],
      },
    ],

    powertrain: [
      {
        link: "https://www.linkedin.com/in/-andy-cai/",
        name: "ANDY CAI",
        position: "ACCUMULATOR ADVISOR",
        photo: images["AndyCai.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/leftraru-gonzalez-tucas-5509a91b6/",
        name: "LEFTRARU GONZÁLEZ-TUCAS",
        position: "ACCUMULATOR CO-LEAD",
        photo: images["LeftraruGonzalezTucas.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/auriano/",
        name: "AURIANO JULSAIN",
        position: "ACCUMULATOR CO-LEAD",
        photo: images["AurianoJulsain.jpg"],
      },
      {},
      {
        link: "https://www.linkedin.com/in/pletourneau4873/",
        name: "PHILIPE LÉTOURNEAU",
        position: "MOTORS AND INVERTERS LEAD",
        photo: images["PhilipeLetourneau.jpg"],
      },
    ],

    vehicledynamicscontrol: [
      {
        link: "https://www.linkedin.com/in/gregapope/",
        name: "GREGORY POPE",
        position: "CONTROLS ADVISOR",
        photo: images["GregoryPope.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/alexandru-bangala-bb48a21ba/",
        name: "ALEXANDRU BANGALA",
        position: "CONTROLS LEAD",
        photo: images["AlexandruBangala.jpg"],
      },
      {
        link: "https://www.linkedin.com/in/kattly-li/",
        name: "KATTLY LI",
        position: "VEHICLE DYNAMICS/CONTROLS ADVISOR",
        photo: images["KattlyLi.jpg"],
      },
      {},
      {
        link: "https://www.linkedin.com/in/nicolas-thirion/",
        name: "NICHOLAS THIRION",
        position: "VEHICLE DYNAMICS LEAD",
        photo: images["NicholasThirion.jpg"],
      },
    ],
  },
];
