import React, { useState, useEffect } from "react";
import Carousel from "../Global Components/Carousel";
import Spinner from "../Global Components/Spinner";

import chassisUnveiling23 from "../Text/Pages/Gallery";

function Gallery() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
      <div className={isLoading ? "hidden fade" : " fade"}>
        <div className="subheading">Chassis Unveiling 23'</div>
        <Carousel images={chassisUnveiling23}></Carousel>
        {/* <div className="subheading">Chassis Unveiling 23'</div>
        <Carousel images={chassisUnveiling23}></Carousel>
        <div className="subheading">Chassis Unveiling 23'</div>
        <Carousel images={chassisUnveiling23}></Carousel> */}
      </div>
    </>
  );
}

export default Gallery;
